@import "./src/scss/shared";

img[src*="liadm"], img[src*="idpix"] {
  display: none;
}

.page-theme-v1 {

  font-family: $body-font-family;
  font-size: $body-font-size;
  line-height: $body-line-height;

  .container {

    max-width: $container;
    margin-left: auto;
    margin-right: auto;

    @include media(tablet) {
      max-width: $tablet-container;
      padding: 0;
    }

    @include media(mobile) {
      max-width: $mobile-container;
    }

  }

  .lead {

    margin-top: 0;
    margin-bottom: 10px;

    @include media(mobile) {
      font-size: 14px;
      line-height: 20px;
    }

    &--fw_normal {
      font-weight: 500;
    }

    &--op_8 {
      opacity: 0.8;
    }

    &--op_7 {
      opacity: 0.7;
    }

    &--op_55 {
      opacity: 0.55;
    }

  }

  .row-title {

    min-height: 120px;
    background-color: rgba(0, 0, 0, 0.02);
    display: flex;
    align-items: center;
    justify-content: center;

    &__heading {
      margin-bottom: 0 !important;
      font-size: 32px !important;
      text-align: center;
      padding: 0 15px;
    }

    /* IE11 Fix */
    &:after {
      content: '';
      min-height: inherit;
      font-size: 0;
    }

  }

  .brand-title {
    color: rgba(0, 0, 0, 0.7);
    font-family: Sentinel, sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2.1px;
    line-height: 16px;
    text-transform: uppercase;
  }

  .error {
    display: block;
    width: 100%;
    color: red;
    font-size: .8em;
    font-style: italic;
  }

  .dropdown {

    @include reset-ul;

    background-color: #ffffff;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 3px 0;
    cursor: default;

    &__item {

      padding: 5px 13px;
      margin: 0 3px;

      &:hover {
        background-color: #F2F2F2;
      }
    }

    &__link {
      color: rgba(0, 0, 0, 0.75);
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.5px;
      text-decoration: none;
      display: flex;
      transition: 0.5s;

      &:hover {
        color: $purple;
        text-decoration: none;
      }

    }

  }

  .menu-item {

    display: block;
    color: rgba(0, 0, 0, 0.75);
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;

    &--sm {
      font-size: 11px;
    }

    &--active {
      color: $purple;
    }

  }

  /* Utilities */

  .d-flex {
    display: flex;
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-between {
    justify-content: space-between;
  }

  .m-0 {
    margin: 0 !important;
  }

  .no-margin {
    margin: 0 !important;
  }

  @include media(tablet) {
    .desktop-only {
      display: none !important;
    }
  }

}
