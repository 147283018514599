@import "./src/scss/shared_v2";

.follow-us {

  $parent: &;

  display: flex;

  &__link {

    margin-right: 16px;
    width: 32px;
    height: 32px;

    svg g[opacity='0.6'] {
      transition: 0.2s;
    }

    &:hover svg g[opacity='0.6'] {
      transition: 0.2s;
      opacity: 0.75;
    }

  }

}
