@mixin media($media) {
  @if $media == tablet {
    @media (max-width: $tablet-breakpoint) {
      @content;
    }
  } @else if $media == tablet-up {
    @media (min-width: $tablet-breakpoint + 1) {
      @content;
    }
  } @else if $media == tablet-only {
    @media (max-width: $tablet-breakpoint) and (min-width: $mobile-breakpoint + 1) {
      @content;
    }
  } @else if $media == mobile {
    @media (max-width: $mobile-breakpoint) {
      @content;
    }
  } @else if $media == mobile-up {
    @media (min-width: $mobile-breakpoint + 1) {
      @content;
    }
  }
}

@mixin container() {

  max-width: $container;
  margin-left: auto;
  margin-right: auto;

  @include media(tablet) {
    max-width: $tablet-container;
    padding: 0;
  }

  @include media(mobile) {
    max-width: $mobile-container;
  }

}

@mixin tab-sub-container {
  max-width: 574px;
  margin-left: auto;
  margin-right: auto;
}
