@mixin arrow-regular($color, $rotate:45, $width:2, $thin:2) {
  border: solid $color;
  display: inline-block;
  border-width: 0 #{$thin}px #{$thin}px 0;
  padding: #{$width}px;
  transform: rotate(#{$rotate}deg);
}

@mixin arrow-solid($color, $rotate:0, $width:6, $thin:1) {
  width: 0;
  height: 0;
  border-top: #{$width/$thin}px solid transparent;
  border-bottom: #{$width/$thin}px solid transparent;
  border-left: #{$width}px solid $color;
  transform: rotate(#{$rotate}deg);
}

@mixin border-box() {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15), 0 6px 10px -5px rgba(0, 0, 0, 0.3);
  background: linear-gradient(180deg, #FFFFFF 0%, #FAFAFA 100%);
  border: 2px solid #FFFFFF;
}

@mixin close-icon($color:#9c9a9a, $opacity: 0.6, $w:38px, $h:38px) {

  position: relative;
  width: $w;
  height: $h;
  margin-left: auto;
  opacity: $opacity;
  cursor: pointer;

  &:before, &:after {
    position: absolute;
    top: 8px;
    left: 18px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: $color;
    border-radius: 1px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

}

/* Tmp before all website will move to react */
@mixin reset-ul() {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Tmp before all browser will support Flexbox vertical center position absolute elements */
@mixin vertical-center {
  top: 50%;
  transform: translateY(-50%);
}

@mixin vertical-center-none {
  top: initial;
  transform: translateY(0);
}

@mixin horizontal-center {
  left: 50%;
  transform: translateX(-50%);
}

@mixin IE11 {
  /* IE11 Fix */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}
